
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Typography, Divider, Paper, Alert, Stack, styled } from "@mui/material";
import { Button } from "@gisce/oficina-virtual-components";
import _ from "lodash";
import Settings from "../../settings";
import AddressField from "../form-fields/AddressField";
import CheckboxField from "../form-fields/CheckboxField";
import EmailField, { validEmail } from "../form-fields/EmailField";
import FileUploadField, { validateFileUpload } from "../form-fields/FileUploadField";
import LangSelectField from "../form-fields/LangSelectField";
import RadioGroupField from "../form-fields/RadioGroupField";
import TextField from "../form-fields/TextField";
import VATField, { isCompanyVAT, validateVAT } from "../VATField";
import { resetLeadsErrors } from "../../actions/electricityContractation";
import { useTranslation } from "react-i18next";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

const NoFlagMuiTelInputStyled = styled(MuiTelInput)`
.MuiTelInput-IconButton {
  display: none;
}
`;

const FormAboutYou = ({gas, onGoBack, onSubmit}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const elecContractation = useSelector((state) => state.electricityContractation);

  const handleSubmit = async (values) => {
    values = Object.assign({}, elecContractation, values)
    values.company = isCompanyVAT(values.vat);
    values.mobile = removePhonePrefix(values.mobile);
    await onSubmit(values)
  };

  const isReadOnly = (field) => {
    let r = false;
    if (elecContractation.contractationConfig && elecContractation.contractationConfig?.[field]) {
      r = true;
    }
    return r;
  };

  const handleVATChange = () => {
    dispatch(resetLeadsErrors())
  }

  const MuiTelComponent = ({input, readOnly, meta}) => <NoFlagMuiTelInputStyled
    disableDropdown={true}
    disabled={readOnly}
    inputProps={{ maxLength: 12 }}
    label={t('common:text.lead_contact_telephone')}
    forceCallingCode={true}
    defaultCountry="ES"
    fullWidth={true}
    helperText={meta.touched && meta.error}
    error={meta.touched && !!meta.error}
    {...input}
  />

  const removePhonePrefix = (phone) => {
    return !phone.startsWith("+34") ? phone : phone.slice(3);
  }

  // It is necessary for mui-tel-input to validate correctly
  const addInitialValuePhonePrefix = (phone) => {
    return phone.startsWith("+34") ? phone : '+34' + phone;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        vat: elecContractation.vat,
        company: elecContractation.company,
        name: elecContractation.name,
        surName1: elecContractation.surName1,
        surName2: elecContractation.surName2,
        cif: elecContractation.cif,
        changeOwner: elecContractation.changeOwner,
        vatRepresentante: elecContractation.vatRepresentante,
        nameRepresentante: elecContractation.nameRepresentante,
        surName1Representante: elecContractation.surName1Representante,
        surName2Representante: elecContractation.surName2Representante,
        sameInvoiceAddress: elecContractation.sameInvoiceAddress,
        invoiceAddress: elecContractation.invoiceAddress,
        commsLanguage: elecContractation.commsLanguage,
        email: elecContractation.email,
        mobile: addInitialValuePhonePrefix(elecContractation.mobile ?? ''),
        nifFaceup: elecContractation.nifFaceup,
      }}
      validate={(values) =>  {
        const errors = {};
        if (!values.vat) {
          errors.vat = t('common:text.required_field');
        } else if (!validateVAT(values.vat)) {
          errors.vat = t('common:text.vat_field_validation_invalid');
        }

        if (!values.name) {
          errors.name = t('common:text.required_field');
        }

        if (isCompanyVAT(values.vat)) {
          if (_.get(Settings, 'newContract.restrictCompanyVAT', false)) {
            errors.vat = t('common:text.vat_company_not_allowed');
          }
          if (_.isEmpty(values.vatRepresentante)) {
            _.set(errors, 'vatRepresentante', t('common:text.required_field'));
          } else if (!validateVAT(values.vatRepresentante)) {
            errors.vatRepresentante = t('common:text.vat_field_validation_invalid');
          }

          if (!values.nameRepresentante) {
            errors.nameRepresentante = t('common:text.required_field');
          }

          if (!values.surName1Representante) {
            errors.surName1Representante = t('common:text.required_field');
          }

          const nifEmpresaFaceUpErrors = validateFileUpload(values.nifEmpresa, {
            min: 1,
            minMessage: t('common:text.multiupload_validation_min'),
          });
          if (nifEmpresaFaceUpErrors) {
            _.set(errors, "nifEmpresa", nifEmpresaFaceUpErrors);
          }

        } else {
          if (!values.surName1) {
            errors.surName1 = t('common:text.required_field');
          }
        }

        if (!values.sameInvoiceAddress) {
          if (!values.invoiceAddress.zipcode) {
            _.set(errors, "invoiceAddress.zipcode", t('common:text.required_field'));
          } else if (values.invoiceAddress.zipcode.length !== 5) {
            _.set(
              errors,
              "invoiceAddress.zipcode",
              t('common:text.contractation_postal_code_length')
            );
          }

          // City
          if (!values.invoiceAddress.city) {
            _.set(errors, "invoiceAddress.city", t('common:text.required_field'));
          }

          // Street Type
          if (!values.invoiceAddress.tv) {
            _.set(errors, "invoiceAddress.tv", t('common:text.required_field'));
          }

          // Street Name
          if (!values.invoiceAddress.nv) {
            _.set(errors, "invoiceAddress.nv", t('common:text.required_field'));
          } else if (values.invoiceAddress.nv.length >= 256) {
            _.set(
              errors,
              "invoiceAddress.nv",
              t('common:text.address_validation_nv_length')
            );
          }

          // Street Number
          if (!values.invoiceAddress.pnp) {
            _.set(errors, "invoiceAddress.pnp", t('common:text.required_field'));
          } else if (values.invoiceAddress.pnp.length >= 9) {
            _.set(
              errors,
              "invoiceAddress.pnp",
              t('common:text.address_validation_pnp_length')
            );
          }

          // Block
          if (
            values.invoiceAddress.bq &&
            values.invoiceAddress.bq.length >= 4
          ) {
            _.set(
              errors,
              "invoiceAddress.bq",
              t('common:text.address_validation_bq_length')
            );
          }

          // Stair
          if (
            values.invoiceAddress.es &&
            values.invoiceAddress.es.length >= 4
          ) {
            _.set(
              errors,
              "invoiceAddress.es",
              t('common:text.address_validation_es_length')
            );
          }

          // Floor
          if (
            values.invoiceAddress.pt &&
            values.invoiceAddress.pt.length >= 10
          ) {
            _.set(
              errors,
              "invoiceAddress.pt",
              t('common:text.address_validation_pt_length')
            );
          }

          // Door
          if (
            values.invoiceAddress.pu &&
            values.invoiceAddress.pu.length >= 4
          ) {
            _.set(
              errors,
              "invoiceAddress.pu",
              t('common:text.address_validation_pu_length')
            );
          }
        }

        // changeOwner
        if (
          elecContractation.activeContract === "yes" && values.changeOwner == null && _.get(Settings, 'newContract.requireMarkChangeOwner', true)
        ) {
          _.set(
            errors,
            "changeOwner",
            t('common:text.required_field')
          );
        }

        // commsLanguage
        if (
          values.commsLanguage == null &&
          _.get(
            Settings,
            gas ? "newDualContract.commsLanguage" : "newContract.commsLanguage",
            true
          )
        ) {
          _.set(
            errors,
            "commsLanguage ",
            t('common:text.required_field')
          );
        }

        if (!values.mobile || values.mobile.length < 4) {
          errors.mobile = t('common:text.required_field');
        } else if (!matchIsValidTel(values.mobile)) {
          errors.mobile = t('common:text.contractation_mobile_not_valid');
        }

        if (_.get(Settings, 'newContract.emailRequired', true) && !values.email) {
          errors.email = t('common:text.required_field');
        } else if (!validEmail(values.email)) {
          errors.email = t('common:text.contractation_email_not_valid');
        }

        const nifFaceupErrors = validateFileUpload(values.nifFaceup, {
          min: 1,
          minMessage: t('common:text.multiupload_validation_min'),
        });
        if (nifFaceupErrors) {
          _.set(errors, "nifFaceup", nifFaceupErrors);
        }

        return errors;
      }}
      render={({ handleSubmit, submitting, values }) => {
        // 'company' es fa servir per canviar el render del form. Fem l'and per restringir-ho quan no acceptem CIFs.
        // TODO: FIX setting
        let company = isCompanyVAT(values.vat) && !_.get(Settings, 'newContract.restrictCompanyVAT', false);
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={{ml: {md: 5}, mr: {md: 5}}}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} sx={{pr: {xs: 24, sm: 0}}}>
                  <Typography variant="h6">
                    {t('common:text.contractation_personal_data')}
                  </Typography>
                  <Field
                    name="vat"
                    render={({input: {onChange, onBlur, value}, meta}) => {
                      return (
                        <VATField
                          value={value}
                          onBlur={onBlur}
                          onChange={(value) => {
                            handleVATChange(value);
                            onChange(value)
                          }}
                          error={meta.error}
                          showError={meta.touched}
                          style={{ width: "100%" }}
                        />
                      )
                    }}
                    label={
                      t(
                        _.get(Settings, 'newContract.restrictCompanyVAT', false) ?
                          'common:text.contractation_nif_without_cif'
                        :
                          'common:text.contractation_nif'
                      )
                    }
                    readOnly={isReadOnly("vat")}
                    style={{width: "100%"}}
                  />
                </Grid>
                <Grid container item xs={12} spacing={3} style={{paddingRight: 0}}>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="name"
                      component={TextField}
                      style={{width: "100%"}}
                      label={
                        company
                          ? t('common:text.contractation_social_reason')
                          : t('common:text.contractation_name')
                      }
                      readOnly={isReadOnly("name")}
                    />
                  </Grid>
                  {!company && (
                    <Grid item xs={12} md={4}>
                      <Field
                        name="surName1"
                        component={TextField}
                        label={t('common:text.contractation_surname1')}
                        readOnly={isReadOnly("surName1")}
                        style={{width: "100%"}}
                      />
                    </Grid>
                  )}
                  {!company && (
                    <Grid item xs={12} md={4}>
                      <Field
                        name="surName2"
                        component={TextField}
                        label={t('common:text.contractation_surname2')}
                        readOnly={isReadOnly("surName2")}
                        style={{width: "100%"}}
                      />
                    </Grid>
                  )}
                  <Grid container item xs={12}>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="mobile"
                        component={MuiTelComponent}
                        label={t('common:text.contractation_mobile_phone')}
                        readOnly={isReadOnly("mobile")}
                        style={{width: "100%"}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="email"
                        component={EmailField}
                        label={
                          t('common:text.contractation_email')
                          + `${_.get(Settings, 'newContract.emailRequired', true) ? "*" : ""}`
                        }
                        readOnly={isReadOnly("email")}
                        style={{width: "100%"}}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                </Grid>
                {company &&
                  <>
                    <Grid container spacing={3} item xs={12} sx={{pr: 0, pt: 0}}>
                      <Grid item xs={12} sx={{pb: {md: 0}}}>
                        <Typography variant="h6">
                          {t('common:text.contractation_legal_representative')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4} style={{paddingRight: 0}}>
                        <Field
                          name="vatRepresentante"
                          render={({input: {onChange, value}, meta}) => {
                            return (
                              <VATField
                                value={value}
                                onBlur={(event) => onChange(event)}
                                onChange={onChange}
                                error={meta.error}
                                showError={meta.touched}
                                style={{ width: "100%" }}
                              />
                            )
                          }}
                          label={t('common:text.contractation_nif')}
                          style={{width: "100%"}}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="nameRepresentante"
                        component={TextField}
                        label={t('common:text.contractation_legal_representative_name')}
                        style={{width: "100%"}}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="surName1Representante"
                        component={TextField}
                        label={t('common:text.contractation_legal_representative_surname1')}
                        style={{width: "100%"}}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="surName2Representante"
                        component={TextField}
                        label={t('common:text.contractation_legal_representative_surname2')}
                        style={{width: "100%"}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Paper style={{padding: 15}} variant="outlined">
                        <Field
                          name="nifFaceup"
                          component={FileUploadField}
                          min={1}
                          max={2}
                          label={t('common:text.contractation_dni_front') + "*"}
                          hint={t('common:text.contractation_doc_invoice_helper', {doc: 'DNI'})}
                          anotherLabel={t('common:text.contractation_dni_back')}
                          removeLabel={t('common:text.remove')}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider/>
                    </Grid>
                  </>
                }
                {elecContractation.activeContract === "yes" && <Grid item xs={12}>
                  <Paper elevation={3} style={{padding: 15}}>
                    <Typography variant="h6">
                      { _.get(Settings, 'newContract.nameInChangeOwnerQuestion', false) ?
                        t("common:text.contractation_change_owner_question_named", {owner: elecContractation.name + " " + elecContractation.surName1 + " " + elecContractation.surName2})
                        : t("common:text.contractation_change_owner_question")
                      }
                    </Typography>
                    <Field
                      name="changeOwner"
                      component={RadioGroupField}
                      radioElements={
                        [
                          {label: t("common:text.generic_yes"), val: "changeOwner", style: {width: "25%"}},
                          {label: t("common:text.generic_no"), val: "dontChangeOwner", style: {width: "25%"}}
                        ]
                      }
                      row
                    />
                  </Paper>
                </Grid>
                }
                {_.get(
                  Settings,
                  gas ? "newDualContract.commsLanguage" : "newContract.commsLanguage",
                  true
                ) &&
                  <Grid item xs={12} md={6}>
                    <Field name="commsLanguage" component={LangSelectField} />
                  </Grid>
                }
                <Grid item xs={12} style={{paddingBottom: 0}}>
                  <Divider style={{marginBottom: 10}}/>
                  <Field
                    name="sameInvoiceAddress"
                    label={t('common:text.contractation_same_address')}
                    component={CheckboxField}
                  />
                  {!values.sameInvoiceAddress && (
                    <Grid item xs={12}>
                      <Field
                        name="invoiceAddress"
                        component={AddressField}
                        label={t('common:text.contractation_invoicing_address')}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{marginBottom: 20}}/>
                  <Typography variant="h6">
                    {t('common:text.contractation_doc_photos', 
                      {doc: company ? t('common:text.contractation_doc_photo_cif') : "DNI"})
                    }
                  </Typography>
                  <Paper style={{padding: 15}} variant="outlined">
                    {company ? 
                    <Field
                      name="nifEmpresa"
                      component={FileUploadField}
                      min={1}
                      label={t('common:text.contractation_nif_doc') + "*"}
                      hint={
                        t('common:text.contractation_doc_invoice_helper',
                        {doc: t('common:text.contractation_doc_photo_cif')})
                      }
                      anotherLabel={t('common:text.contractation_nif_add')}
                      removeLabel={t('common:text.remove')}
                    />
                    :
                    <Field
                      name="nifFaceup"
                      component={FileUploadField}
                      min={1}
                      max={2}
                      label={t('common:text.contractation_dni_front') + "*"}
                      hint={t('common:text.contractation_doc_invoice_helper', {doc: 'DNI'})}
                      anotherLabel={t('common:text.contractation_dni_back')}
                      removeLabel={t('common:text.remove')}
                    />  
                  }
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            <Divider sx={{mt: 3, mb: 3}}/>
            <Button
              variant={'text'}
              onClick={onGoBack}
              style={{ marginRight: 12 }}
              disabled={submitting}
            >
              {t('common:text.contractation_previous')}
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              type="submit"
              disabled={Object.values(elecContractation.leadErrors).some((x) => x)}
              loading={submitting}
            >
              {t('common:text.contractation_next')}
            </Button>

            <Stack sx={{mt: 3}} gap={1}>
              { elecContractation.leadErrors.badScore && 
                <Alert severity={"error"} variant="filled">
                  {t("common:text.contractation_leads_bad_score")}
                  <br></br>
                  {t("common:text.contractation_leads_bad_score_thanks")}
                </Alert>
              }
              { elecContractation.leadErrors.dniInvalid &&
                <Alert severity={"error"} variant="filled">
                  {t("common:text.contractation_leads_dni_invalid")}
                </Alert>
              }
            </Stack>
          </form>
        );
      }}
    />
  )
};

export default FormAboutYou;
