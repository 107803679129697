import {
  Box,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { i18n } from "../../../../config";

export const BatterySelectorItem = ({
  batteryDistribution,
  selected,
  onClick,
}) => {
  return (
    <Paper
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
      variant="outlined"
      elevation={2}
    >
      <ListItem
        button
        onClick={() => onClick?.(batteryDistribution)}
        selected={selected}
        style={{ cursor: selected ? "" : "pointer" }}
      >
        <Box sx={{ width: "100%" }}>
          <Box style={{ display: "flex" }}>
            <Typography variant="subtitle2">
              {i18n.t("common:text.altHome_virtual_battery")}:
            </Typography>
            <Typography variant="body2">
              {batteryDistribution["batteryName"]}
            </Typography>
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography variant="subtitle2">{i18n.t("common:text.contracts_title")}: </Typography>
            <Typography variant="body2">
              {batteryDistribution["distribution"].map((distribution, i) => {
                const content = `${distribution["contractName"]}(${distribution["pesPercentage"]}%)`
                return (i === batteryDistribution["distribution"].length-1) ?  `${content} ` : `${content}, `;
              })}
            </Typography>
          </Box>
        </Box>
      </ListItem>
    </Paper>
  );
};

export default BatterySelectorItem;
