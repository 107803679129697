import {
  RECEIVE_BATERIA_VIRTUAL_PERCENTAGES,
  FETCH_BATERIA_VIRTUAL_PERCENTAGES,
  SET_BATERIA_PERCENTATGE_REQUEST,
  SET_BATERIA_PERCENTATGE_DONE,
  SET_BATERIA_PERCENTATGE_ERROR,
  SET_MOVE_BATERIA_REQUEST,
  SET_MOVE_BATERIA_DONE,
  SET_MOVE_BATERIA_ERROR,
  FETCHING_VIRTUAL_BATTERY_TYPES,
  RECEIVE_VIRTUAL_BATTERY_TYPES,
  UPDATING_VIRTUAL_BATTERY_TYPES,
  UPDATED_VIRTUAL_BATTERY_TYPES,
} from '../constants';
import { createReducer } from '../utils/misc';

const initialState = {
    error: false,
    waitingChangeRequest:false,
    isFetchingBateriaVirtualPercentages: false,
    bateriaVirtualPercentages: [],
    batteryOrigTypes: {},
    virtualBatteryTypes: {}
};

export default createReducer(initialState, {
  [RECEIVE_BATERIA_VIRTUAL_PERCENTAGES]: (state, payload) =>
    Object.assign({}, state, {
        bateriaVirtualPercentages: payload.map((e) => Object.assign(e, {percentage: parseInt(e.percentage.toFixed(0))})) ,
        isFetchingBateriaVirtualPercentages: false,
    }),
  [FETCH_BATERIA_VIRTUAL_PERCENTAGES]: (state, payload) =>
    Object.assign({}, state, {
        bateriaVirtualPercentages: [],
        isFetchingBateriaVirtualPercentages: true,
    }),
  [SET_BATERIA_PERCENTATGE_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      waitingChangeRequest: true,
      error: false,
    }),
  [SET_BATERIA_PERCENTATGE_DONE]: (state, payload) =>
    Object.assign({}, state, {
      waitingChangeRequest: false,
      error: false,
    }),

  [SET_BATERIA_PERCENTATGE_ERROR]: (state, payload) =>
    Object.assign({}, state, {
      waitingChangeRequest: false,
      error: payload,
    }),

  [SET_MOVE_BATERIA_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      waitingChangeRequest: true,
      error: false,
    }),
  [SET_MOVE_BATERIA_DONE]: (state, payload) =>
    Object.assign({}, state, {
      waitingChangeRequest: false,
      error: false,
    }),

  [SET_MOVE_BATERIA_ERROR]: (state, payload) =>
    Object.assign({}, state, {
      waitingChangeRequest: false,
      error: payload,
    }),

  [FETCHING_VIRTUAL_BATTERY_TYPES]: (state, payload) =>
    Object.assign({}, state, {
      virtualBatteryTypes: {
        loading: true
      }
    }),
  [RECEIVE_VIRTUAL_BATTERY_TYPES]: (state, payload) =>
    Object.assign({}, state, {
      virtualBatteryTypes: {
        types: payload,
        loading: false
      }
    }
  ),
  [UPDATING_VIRTUAL_BATTERY_TYPES]: (state, payload) => {
    let newVirtualBateriesTypes = {...state.virtualBatteryTypes};
    newVirtualBateriesTypes.loading = payload.loading;
    let newState = {
      ...state,
      virtualBatteryTypes: newVirtualBateriesTypes
    }
    return newState;
  },
  [UPDATED_VIRTUAL_BATTERY_TYPES]: (state, payload) => {
    //TODO:Cal repassar aquest codi el loading potser millor al redux state no anidat?(per la prealpha arreglarho)
    let newVirtualBateriesTypes = {...state.virtualBatteryTypes};
    newVirtualBateriesTypes.loading = payload.loading;

    let newState = {
      ...state,
      virtualBatteryTypes: newVirtualBateriesTypes
    }
    return newState;
  },
  EXPORT_VIRTUAL_BATTERIES_REQUEST: (state, payload) => {
    const download_status = Object.assign({}, state.download_status);
    download_status[payload.batteries[0]] = true;
    return Object.assign({}, state, {
      download_status,
      message_text: payload.message
    });
  },
  EXPORT_VIRTUAL_BATTERIES_DONE: (state, payload) => {
    const download_status = Object.assign({}, state.download_status);
    download_status[payload.batteries[0]] = false;
    return Object.assign({}, state, {
        download_status
    });
  },
  MESSAGE_VIRTUAL_BATTERIES: (state, payload) => {
    return Object.assign({}, state, {
      message_text: payload.message,
    })
  }
});
