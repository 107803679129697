import { Grid } from "@mui/material";
import VirtualBatteryPercentageCard from "../../VirtualBatteryPercentageCard";
import { i18n } from "../../../../config";

export const BatteryDistributionCard = ({ batteryDistribution}) => {
  return (
    <Grid container>
      {batteryDistribution["distribution"]?.map((distribution, i) => {
        return (
          <Grid item key={`${distribution["contractName"]}__${i}`}>
            <VirtualBatteryPercentageCard
              key={`${distribution["contractName"]}__${i}`}
              style={{
                flex: 1,
                width: "100%",
              }}
              active={distribution.current}
              locked={distribution["locked"]}
              lockedMessage={i18n.t('common:text.bateria_virtual_locked_help')}
              percentageData={{
                polissa_name: distribution["contractName"],
                percentage: distribution["pesPercentage"],
              }}
            />
          </Grid>
        );
      }
      )}
    </Grid>
  )
}

export default BatteryDistributionCard;
