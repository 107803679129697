import { CredentialsLoginParam, IToken, LoginRequestResponse } from "@/types/models/hooks";
import {
  LOGIN_CREDENTIALS_REQUEST,
  LOGIN_CREDENTIALS_FAILURE,
  LOGIN_CREDENTIALS_SUCCESS,
} from '../../constants/index';
import {
  define_token,
  undefine_token,
  get_token
} from '../../utils/http_functions';
import { Dispatch } from "redux";
import { decodeJwt } from "jose";

export const loginCredentialsSuccess = (token: string) => {
  define_token(token);
	const decodedToken = decodeJwt<IToken>(token);

  return {
    type: LOGIN_CREDENTIALS_SUCCESS,
    payload: {
      token,
      tokenVals: decodedToken
    }
  };
}

export const loginCredentialsFailure = ({
  statusCode = 403, 
  statusText = '', 
  statusType = "error"
} = {}) => {
  undefine_token();
  return {
    type: LOGIN_CREDENTIALS_FAILURE,
    payload: {
      statusCode,
      statusText,
      statusType,
    },
  };
}

export const loginCredentialsRequest = () => {
  return {
    type: LOGIN_CREDENTIALS_REQUEST
  }
}

export const loginCredentials = ({
  user, 
  password
}: CredentialsLoginParam) => {
  return (dispatch: Dispatch) => {
    dispatch(loginCredentialsRequest());
    get_token(user, password)
    .then(response => {
      if (response.data.token) {
        dispatch(loginCredentialsSuccess(response.data.token));
      } else {
        dispatch(loginCredentialsFailure({statusCode: 403}));
      }
    }).catch(error => {
      dispatch(loginCredentialsFailure(error));
    });
  }
}