import { useState, useEffect } from "react";
import MultiUploadButton, { validate } from "../MultiUploadButton";
import { i18n } from "../../config";

const FileUploadField = ({
  input: { value, onChange },
  min,
  max,
  label,
  hint,
  anotherLabel,
  removeLabel,
  readOnly = false,
  onUpdate = null,
  meta,
  ...rest
}) => {
  const [files, setFiles] = useState(null);

  const handleChange = (fileArray) => {
    setFiles(fileArray);
    onChange(fileArray);
    if (typeof onUpdate === "function") {
      onUpdate(fileArray);
    }
  };

  useEffect(() => {
    if (value) {
      setFiles(value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <MultiUploadButton
      value={value || files}
      onChange={handleChange}
      min={min || 1}
      max={max || 1}
      label={label || i18n.t('common:text.multiupload_label')}
      hint={hint || i18n.t('common:text.multiupload_hint')}
      anotherLabel={anotherLabel || i18n.t('common:text.multiupload_add_file')}
      removeLabel={removeLabel || i18n.t('common:text.multiupload_remove_file')}
      disabled={readOnly}
      {...rest}
      errorText={meta.touched ? meta.error : ""}
    />
  );
};

export default FileUploadField;

export { validate as validateFileUpload };
