import { createReducer } from '../utils/misc';

import {
  LOGIN_CREDENTIALS_SUCCESS,
  LOGIN_CREDENTIALS_FAILURE,
  LOGIN_CREDENTIALS_REQUEST,
  LOGIN_OPENID_SUCCESS,
  LOGIN_OPENID_FAILURE,
  LOGIN_OPENID_REQUEST,
  LOGIN_CLIENTID_SUCCESS,
  LOGIN_CLIENTID_FAILURE,
  LOGIN_CLIENTID_REQUEST,
  LOGIN_REFRESH_REQUEST,
  LOGIN_REFRESH_SUCCESS,
  LOGIN_REFRESH_FAILURE,
  LOGIN_NOT_MADE,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  RECOVER_USER_SUCCESS,
  RECOVER_USER_FAILURE,
  LOGIN_TOKEN_REQUEST,
  LOGIN_TOKEN_SUCCESS,
  LOGIN_TOKEN_FAILURE,
  CLEAR_ERROR,
  SET_REDIRECTED
} from '../constants/index';

const initialState = {
  token: null,
  tokenVals: null,
  redirect: null,
  changePassword: null,
  isAuthenticated: false, // false -> no està autenticat, true -> està autenticat.
  isAuthenticating: true, // false -> no està autenticant, true -> està autenticant.
  isRefreshing: false,
  redirect: null,
  redirectSearchParams: null,
  isRedirectedAfterLogin: false,
  newCustomer: false,
  statusCode: null,
  statusText: null,
  statusType: null, // error, warning, success
  isRegistering: false,
  isRegistered: false,
  registerStatusText: null,
};

const handleSuccess = (state, payload) => {
  return Object.assign({}, state, {
    isAuthenticating: false,
    isAuthenticated: true,
    isRefreshing: false,
    token: payload.token,
    tokenVals: payload.tokenVals,
    redirect: payload?.redirect,
    redirectSearchParams: payload?.redirectSearchParams,
    newCustomer: payload?.newCustomer,
    statusCode: payload?.statusCode,
    statusType: payload?.statusType,
    statusText: payload?.statusText,
  })
};

const handleFailure = (state, payload) => {
  return Object.assign({}, state, {
    isAuthenticating: false,
    isAuthenticated: false,
    token: null,
    openIdToken: null,
    redirect: payload?.redirect,
    statusCode: payload?.statusCode,
    statusType: payload?.statusType,
    statusText: payload?.statusText,
  })
};

export default createReducer(initialState, {
  [LOGIN_CREDENTIALS_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isAuthenticating: true,
      type: "credentials",
      statusCode: null,
      statusType: '',
      statusText: null,
    }),

  [LOGIN_CREDENTIALS_SUCCESS]: handleSuccess,
  [LOGIN_OPENID_SUCCESS]: handleSuccess,
  [LOGIN_TOKEN_SUCCESS]: handleSuccess,
  [LOGIN_CLIENTID_SUCCESS]: handleSuccess,
  [LOGIN_REFRESH_SUCCESS]: handleSuccess,
  [REGISTER_USER_SUCCESS]: handleSuccess,
    
  [LOGIN_CREDENTIALS_FAILURE]: handleFailure,
  [LOGIN_OPENID_FAILURE]: handleFailure,
  [LOGIN_TOKEN_FAILURE]: handleFailure,
  [LOGIN_CLIENTID_FAILURE]: handleFailure,
  [LOGIN_REFRESH_FAILURE]: handleFailure,
  [REGISTER_USER_FAILURE]: handleFailure,

  [LOGIN_NOT_MADE]: (state, payload) => {
    return Object.assign({}, state, {
      isAuthenticating: false,
      statusCode: payload?.statusCode,
      statusType: payload?.statusType,
      statusText: payload?.statusText,
    })
  },
  [CLEAR_ERROR]: (state) => 
    Object.assign({}, state, {
      statusCode: null,
      statusText: null,
      statusType: null
    }), 
  [SET_REDIRECTED]: (state) => 
    Object.assign({}, state, {
      isRedirectedAfterLogin: true
    }),
  [LOGIN_OPENID_REQUEST]: (state) =>
    Object.assign({}, state, {
      isAuthenticating: true,
      statusText: null,
      type: "openid"
    }),
  [LOGIN_TOKEN_REQUEST]: (state) =>
    Object.assign({}, state, {
      isAuthenticating: true,
      statusText: null,
      type: 'token',
    }),
  [LOGIN_CLIENTID_REQUEST]: (state) =>
    Object.assign({}, state, {
      isAuthenticating: true,
      statusText: null,
      type: "clientid"
    }),
  [LOGIN_REFRESH_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isAuthenticating: true,
      isRefreshing: true,
      type: "refresh",
      statusCode: null,
      statusType: '',
      statusText: null,
    }),
  [LOGOUT_REQUEST]: (state) =>
    Object.assign({}, state, {
      statusText: null,
      statusCode: null,
      statusType: '',
      type: "logout"
    }),
  [LOGOUT_SUCCESS]: (state, payload) =>
    Object.assign({}, state, {
      isAuthenticating: false,
      isAuthenticated: false,
      token: null,
      tokenVals: null,
      statusCode: null,
      statusType: '',
      statusText: null,
      registerStatusText: null
    }),
  [REGISTER_USER_REQUEST]: (state) =>
    Object.assign({}, state, {
      isRegistering: true,
      type: "register"
    }),
  [RECOVER_USER_SUCCESS]: (state, payload) =>
    Object.assign({}, state, {
      isAuthenticating: false,
      isAuthenticated: false,
      token: null,
      statusCode: payload?.statusCode,
      statusType: payload?.statusType,
      statusText: payload?.statusText,
    }),
  [RECOVER_USER_FAILURE]: (state, payload) =>
    Object.assign({}, state, {
      isAuthenticating: false,
      isAuthenticated: false,
      token: null,
      statusCode: payload?.statusCode,
      statusType: payload?.statusType,
      statusText: payload?.statusText,
    }),
});
