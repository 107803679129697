import _ from "lodash";
import {
    FETCH_INVOICES_REQUEST,
    FETCH_INVOICES_FILTER_REQUEST,
    RECEIVE_INVOICES,
    RECEIVE_INVOICES_FILTER,
    EXPORT_INVOICE_REQUEST, EXPORT_INVOICE_DONE, MESSAGE_INVOICE, CLEAR_INVOICE_PAGINATION, SET_ITEMS_OFFSET, SET_FILTERS
} from '../constants/index'
import {
    data_fetch_api_resource,
    data_download_api_resource
} from '../utils/http_functions'
import {
    parseJSON
} from '../utils/misc'
// import {
//     logoutAndRedirect
// } from './auth'
import saveAs from 'file-saver';
import Settings from "../settings";
import { i18n } from "../config";


export function fetchInvoicesRequest(initial) {
    const message = (initial)?null:i18n.t('common:text.invoices_updating_list');

    return {
        type: FETCH_INVOICES_REQUEST,
        payload: {
            message,
        },
    };
}

export function fetchInvoicesFilterRequest() {
    return {
        type: FETCH_INVOICES_FILTER_REQUEST
    };
}

export function receiveInvoices(response, initial, filters, errors=null) {
    const message = (initial)?null:i18n.t('common:text.invoices_updated_list');
    return {
        type: RECEIVE_INVOICES,
        payload: {
            response,
            message,
            filters,
            errors
        },
    };
}

export function receiveInvoicesFilter(payload) {
    return {
        type: RECEIVE_INVOICES_FILTER,
        payload
    };
}

export const messageInvoice = message => {
    return {
        type: MESSAGE_INVOICE,
        payload: {
            message
        }
    }
}

export function changeItemsOffset(newItemsOffset) {
    return {
        type: SET_ITEMS_OFFSET,
        payload: {
            itemsOffset: newItemsOffset
        }
    }
} 

export function changeFilter(newFilters, newItemsPage, newItemsOffset) {
    return {
        type: SET_FILTERS,
        payload: {
            itemsPage: newItemsPage,
            itemsOffset: newItemsOffset,
            currentFilter: newFilters
        }
    }
} 

export function clearPagination() {
    return async (dispatch) => {
        dispatch({
            type: CLEAR_INVOICE_PAGINATION
        });
    }
} 

export function fetchFilters(token) {
    return (dispatch) => {
        dispatch(fetchInvoicesFilterRequest());

        const resource = "filter/params?fields=cups,addresses,contracts,vats";

        data_fetch_api_resource(token, resource, 2)
        .then(parseJSON)
        .then((response) => {
            dispatch(receiveInvoicesFilter(response));
        })
        .catch(error => {
            dispatch(receiveInvoicesFilter({}));
            // if (error.status === 401) {
            //     dispatch(logoutAndRedirect(error));
            // }
        });
    }
} 

export function fetchInvoices(token, initial = false, filters = '', offset = 0, limit = Settings?.invoices?.itemsPerPage ?? 100) {
    return (dispatch, getState) => {
        // const filtersCache = getState().invoices.filters?.cache;
        // const foundFilter = filtersCache[filters];
        dispatch(fetchInvoicesRequest(initial));

        // if (foundFilter && foundFilter.items.length < limit + offset) {

        //     dispatch(receiveInvoices(foundFilter, initial, filters));
            
        // } else {
            const resource = `invoices/?offset=${offset}&limit=${limit}&filter=[${filters}]`;

            data_fetch_api_resource(token, resource)
            .then(parseJSON)
            .then((response) => {
                dispatch(receiveInvoices(response, initial, filters));
            })
            .catch((error) => {
                if (error.status === 401) {
                    // dispatch(logoutAndRedirect(error));
                } else {
                    dispatch(receiveInvoices([], initial, filters, error));
                }
            });
        // }
    };
}

export function exportInvoiceRequest(invoice) {
    return {
        type: EXPORT_INVOICE_REQUEST,
        payload: {
            invoice,
            message: null,
        },
    };
}

export function exportInvoiceDone(invoice) {
    return {
        type: EXPORT_INVOICE_DONE,
        payload: {
            invoice
        },
    };
}

export function exportInvoice(token, invoice, report_type) {
    return (dispatch) => {
        dispatch(exportInvoiceRequest(invoice));
        if (_.get(Settings, "invoices.downloadInNewTab", false)) {
            let newTab = window.open(i18n.t('common:url.loading'), '_blank');
            return data_download_api_resource(token, `invoices/${invoice}/${report_type}/`, 1)
                .then(response => response.data)
                .then(blob => URL.createObjectURL(blob))
                .then(anUrl => {
                    newTab.location.href = anUrl;
                    URL.revokeObjectURL(i18n.t('common:url'));
                })
                .catch(error => {
                    // if (error.status === 401) {
                    //     dispatch(logoutAndRedirect(error));
                    // }
                })
                .finally(() => {
                    dispatch(exportInvoiceDone(invoice));
                });
        } else {
            data_download_api_resource(token, `invoices/${invoice}/${report_type}/`, 1)
              .then(response => {
                  const filename = response.headers["content-disposition"].split("=");
                  dispatch(messageInvoice(i18n.t('common:text.invoices_download_ready')));
                  saveAs(response.data, filename[1]);
              })
              .catch(error => {
                //   if (error.status === 401) {
                //       dispatch(logoutAndRedirect(error));
                //   }
              })
              .finally(() => {
                  dispatch(exportInvoiceDone(invoice));
              });
        }
    };
}
