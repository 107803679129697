import _ from "lodash";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";
import Settings from "../settings";
import { marketingHook } from "../overrides/hooks/init";
import {getLangByToken, getVerifiedLanguage} from "../i18n";
import { decodeJwt } from 'jose';

const saveLang = (lang) => {
  lang = getVerifiedLanguage(lang);
  Cookies.set("lang", lang);
};

const getLang = () => {
  const storedLang = Cookies.get("lang");
  const tokenLang = getLangByToken(Cookies.get("token"));

  let lang = Settings.language.default;


  if (storedLang) {
    lang = storedLang;
  } else if (tokenLang) {
    lang = tokenLang;
  }

  return getVerifiedLanguage(lang);
};

const getCustomUserID = () => {
  const token = Cookies.get("token");
  if (!token)
    return null;
  try {
    const token_values = decodeJwt(token);
    const userField = Settings.analytics?.userField ?? "user_id";
    return parseInt(token_values[userField]);
  } catch (e) {
    console.debug('ERROR', e);
    return null;
  }
}

const getMaintenanceBypass = () => {
  return Cookies.get("maintenance_bypass");
};

const hasAcceptedCookies = () => {
  if (Cookies.get("rcl_cookies_accepted")) {
    return true;
  }
  return false;
};

const statisticsConsentGiven = () => {
  return Cookies.get("rcl_statistics_consent");
};

const marketingConsentGiven = () => {
  return Cookies.get("rcl_marketing_consent");
};

const preferenceConsentGiven = () => {
  return Cookies.get("rcl_preferences_consent");
};

const acceptCookies = () => {
  Cookies.set("rcl_cookies_accepted", true);
};

const acceptConsentCookies = () => {
  Cookies.set("rcl_consent_given", true);
};

const acceptMarketingCookies = () => {
  Cookies.set("rcl_marketing_consent", true);
};

const acceptPreferencesCookies = () => {
  Cookies.set("rcl_preferences_consent", true);
};

const acceptStatisticCookies = () => {
  ReactGA.initialize(_.get(Settings, "analytics.key"));
  Cookies.set("rcl_statistics_consent", true);
};

const acceptAllCookies = () => {
  ReactGA.initialize(_.get(Settings, "analytics.key"));
  Cookies.set("rcl_cookies_accepted", true);
  Cookies.set("rcl_marketing_consent", true);
  Cookies.set("rcl_consent_given", true);
  Cookies.set("rcl_preferences_consent", true);
  Cookies.set("rcl_statistics_consent", true);
};

const deleteStatisticCookies = () => {
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
  window[`ga-disable-${_.get(Settings, "analytics.key")}`] = true;
};

const updateStatisticScripts = (location) => {
  if (
    _.get(Settings, "features.analytics", false) &&
    _.get(Settings, "analytics.key", false) &&
    statisticsConsentGiven()
  ) {
    if (
      Settings.features?.analyticsCustomUserID ?? false
    ) {
      const customUserID = getCustomUserID();
      if (customUserID) { ReactGA.set({ userId: customUserID }); }
    }
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }
};

const updateMarketingScripts = () => {
  if (Cookies.get("rcl_marketing_consent")) {
    marketingHook();
  }
};

const cookies = {
  saveLang,
  getLang,
  getMaintenanceBypass,
  acceptConsentCookies,
  statisticsConsentGiven,
  marketingConsentGiven,
  preferenceConsentGiven,
  deleteStatisticCookies,
  acceptStatisticCookies,
  updateStatisticScripts,
  acceptMarketingCookies,
  updateMarketingScripts,
  acceptPreferencesCookies,
  acceptAllCookies,
  hasAcceptedCookies,
  acceptCookies
};

export default cookies;
