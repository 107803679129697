
import { DefaultAxiosRequestConfig } from "@/types/definitions/apiCalls";
import * as Sentry from "@sentry/browser";
import axios, { AxiosRequestConfig } from "axios";

const API_PREFIX = "/api/";


class API {
  static get<TResponse, TAxiosRequestConfig extends DefaultAxiosRequestConfig = DefaultAxiosRequestConfig>(
    url: string, 
    params?: string, 
    config?: AxiosRequestConfig<TAxiosRequestConfig>
  ): Promise<TResponse> {
    return new Promise<TResponse>((resolve, reject) => {
      axios.get<TResponse>([API_PREFIX, url, params].join(''), config)
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          Sentry.captureException(err);
          reject(err);
        });
    });
  }

  static put<TResponse, TData>(url: string, data: TData): Promise<TResponse> {
    return new Promise<TResponse>(async (resolve, reject) => {
      axios.put<TResponse>([API_PREFIX, url].join(''), data)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        Sentry.captureException(err);
        reject(err);
      })
    });
  }

  static post<TResponse, TData>(url: string, data: TData): Promise<TResponse> {
    return new Promise<TResponse>(async (resolve, reject) => {
      axios.post<TResponse>([API_PREFIX, url].join(''), data)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        Sentry.captureException(err);
        reject(err);
      })
    });
  }
}


export default API