import { Button, CircularProgress } from "@mui/material";
import { LowPriority } from "@mui/icons-material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { i18n } from "../../../config";
import MoveVirtualBatteryDialog from "./MoveVirtualBatteryDialog";
import MoveVirtualBatteryContextProvider from "./MoveVirtualBatteryContextProvider";
import { BatteryOriginWarning } from "./BatteryOriginsWarning";

/*
 * Button that opens or closes the dialog of virtual battery movement
 * - Has a state that sets the button in loading and disabled
 * - Has the ContextProvider needed for the virtual battery movement components
 * */
const BateriaVirtualMoveVirtualBatteryDialogOpenButton = ({
  bateriaVirtual,
  token,
}) => {
  const sending = useSelector(
    (state) => state.bateriaVirtual.waitingChangeRequest
  );
  const {readonly: tokenReadOnly} = useSelector(state => state.auth.tokenVals);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Button
        variant={"contained"}
        color={"primary"}
        onClick={handleDialogOpen}
        disabled={tokenReadOnly}
      >
        {sending ? (
          <CircularProgress
            color="secondary"
            style={{ marginRight: 10 }}
            size={25}
          />
        ) : (
          <LowPriority style={{ marginRight: 10 }} />
        )}
        {i18n.t("common:text.virtual_battery_move")}
      </Button>

      {bateriaVirtual.es_origen ? (
        dialogOpen && <BatteryOriginWarning onAccept={() => setDialogOpen(false)}/>
      ) : (
        <MoveVirtualBatteryContextProvider>
          <MoveVirtualBatteryDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            bateriaVirtual={bateriaVirtual}
            token={token}
          />
        </MoveVirtualBatteryContextProvider>
      )}
    </>
  );
};

export default BateriaVirtualMoveVirtualBatteryDialogOpenButton;
