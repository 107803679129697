import { FETCH_PROFILE_REQUEST, RECEIVE_PROFILE, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_OK, UPDATE_PROFILE_KO, RECEIVE_PROFILE_KO } from '../constants/index'
import { data_fetch_api_resource, data_update_api_resource } from '../utils/http_functions'
import { parseJSON } from '../utils/misc'

export function receiveProfile(data) {
    return {
        type: RECEIVE_PROFILE,
        payload: data,
    };
}

export function receiveProfileError(data) {
    return {
        type: RECEIVE_PROFILE_KO,
    };
}

export function fetchProfileRequest() {
    return {
        type: FETCH_PROFILE_REQUEST,
    };
}


export function receiveUpdateProfile(data) {
    return {
        type: UPDATE_PROFILE_OK,
        payload: {
            data,
            statusText: "Changes applied correctly",
            statusType: "info",
        },
    };
}

export function receiveUpdateProfileKO({status: statusCode = 403, statusText = '', statusType = "error"}) {
    return {
        type: UPDATE_PROFILE_KO,
        payload: {
            statusCode,
            statusText, // "The provided credentials are not correct" TODO
            statusType,
        },
    };
}


export function updateProfileRequest() {
    return {
        type: UPDATE_PROFILE_REQUEST,
    };
}
export function fetchProfile(token) {
    return (dispatch) => {
        dispatch(fetchProfileRequest());
        data_fetch_api_resource(token, "users/" )
            .then(parseJSON)
            .then(response => {
                if (response !== null) {
                    dispatch(receiveProfile(response.result));
                } else {
                    dispatch(receiveProfileError());
                }
            })
            .catch(error => {
                if (error.response.status === 409 || error.status === 409) {
                    dispatch(receiveProfileError(error.response.data));
                }
                // if (error.status === 401) {
                //     dispatch(logoutAndRedirect());
                // }
            });
    };
}


export function updateProfile(token, data) {
    return (dispatch) => {
        dispatch(updateProfileRequest());
        data_update_api_resource(token, "user/", data )
            .then(parseJSON)
            .then(response => {
                if (response.result.was_updated)
                    dispatch(receiveUpdateProfile(response.result));
                else
                    dispatch(receiveUpdateProfileKO());

            })
            .catch(error => {
                // if (error.status === 401) {
                //     dispatch(logoutAndRedirect(error));
                // }
            });
    };
}
