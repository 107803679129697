import { default as couponService } from "@/services/coupons";

import {
  REGISTER_COUPON
} from "../constants";
import { Dispatch } from 'redux';

// ----------------------------------------------------------------------------
// Coupons
// ----------------------------------------------------------------------------

//TODO: Interfície temporal, moure més endavant
interface IRegisterCouponPayload {
  qs: {
    promocodes?: string[];
  }
}

export const registerCoupon = (payload: IRegisterCouponPayload) => {
  if ('promocodes' in payload['qs']) {

    return {
      type: REGISTER_COUPON,
      payload: {
        coupons: payload['qs']['promocodes']
      },
    };
  }
};

export const fetchCouponsRequest = () => {
  return {
    type: 'FETCH_COUPONS_REQUEST',
  }
}

export const fetchCouponsReceive = (response: Awaited<ReturnType<typeof couponService.fetchCoupons>>) => {
  
  return {
    type: 'FETCH_COUPONS_RECEIVE',
    payload: {
      coupons: response
    }
  }
}

export const fetchCoupons = (lang: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchCouponsRequest());
    couponService.fetchCoupons(lang)
    .then(response => {
      dispatch(fetchCouponsReceive(response));
    })
    // .catch(err => {});
  };
}
