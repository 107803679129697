import { Grid } from "@mui/material";
import BatteryDistributionCard from "./BatteryDistributionCard";

export const BatteryDistributionAfter = ({ batteryDistribution }) => {
  return (
    <Grid container style={{ position: "relative" }}>
      <BatteryDistributionCard batteryDistribution={batteryDistribution} />
    </Grid>
  );
};

export default BatteryDistributionAfter;
