import { Box, Paper, Typography } from "@mui/material";
import useBatteryDistributionPreview from "./useBatteryDistributionPreview";
import {
  BatteryDistributionAfter,
} from "./BatteryDistributionCard";
import { useSelector } from "react-redux";

export const BatteryDistributionPreview = ({ currentBatteryDistribution, newPercentage, selectedBatteryId, expectedAction="move" }) => {
  const { items, view_contract } = useSelector(
    (state) => state.contracts
  );
  const { getExpectedDistribution } = useBatteryDistributionPreview();

  let distributionParams = {
    contracts: items,
    newPercentage,
    forceCurrentName: view_contract.name
  };


  if (expectedAction === "changePercentage") {
    distributionParams["expectedAction"] = "changePercentage";
    distributionParams["contractUpdated"] = view_contract.id;
    distributionParams["editedBatteryId"] = selectedBatteryId;
  } else {
    distributionParams["movedBatteryId"] = selectedBatteryId;
    distributionParams["expectedAction"] = "move";

  }
  const newExpectedDistribution = getExpectedDistribution(distributionParams);

  return (
    <Box>
      <Paper
        style={{
          padding: "1rem",
          overflowY: "auto",
          maxHeight: "35vh",
        }}
        variant="outlined"
      >
        <Box style={{ position: "relative" }}>
          <BatteryDistributionAfter batteryDistribution={newExpectedDistribution} />
        </Box>
      </Paper>
    </Box>
  );
};

export default BatteryDistributionPreview;
