import {
    RECEIVE_CONTRACTS,
    FETCH_CONTRACTS_REQUEST,
    FILTER_CONTRACTS_REQUEST,
    CLEAR_CONTRACTS_FILTER,
    EXPORT_CONTRACT_REQUEST,
    EXPORT_CONTRACT_DONE,
    MESSAGE_CONTRACT,
    SET_CONTRACT_ITEMS,
    SYNC_SINGLE_CONTRACT,
    LOGOUT_SUCCESS
} from '../constants';
import { bateriaVirtualFixDecimals } from '../utils/contracts';
import { createReducer } from '../utils/misc';

const initialState = {
    isFetching: false,
    loading: 'idle', // 'idle' | 'pending' | 'succeeded' | 'failed'
    downloading_contract: false,
    filterContract: null,
    editFormOpen: false,
    message: null,
    contractAction: null, // string
    view_contract: null,
    items: [],
};

export default createReducer(initialState, {
    [SET_CONTRACT_ITEMS]: (state, payload) =>
        Object.assign({}, state, {
          items: bateriaVirtualFixDecimals(payload, 0),
        }),
    [SYNC_SINGLE_CONTRACT]: (state, payload) => {
      const newState = {...state};
      const newContract = payload.contract ?? false;
      if (newContract) {
        if (newState.view_contract && newState.view_contract.id === newContract.id) {
          newState.view_contract = newContract;
        }
        if (newState.items) {
          const index = newState.items.findIndex((c) => c.id === newContract.id);
          if (index !== -1) {
            newState.items[index] = newContract;
          }
        }
      }

      return newState;
    },
    [RECEIVE_CONTRACTS]: (state, payload) =>
      Object.assign({}, state, {
          items: bateriaVirtualFixDecimals(payload.response.items, 0),
          total_items: payload.response.n_items,
          offset_items: payload.response.offset,
          page_items: payload.response.limit,
          isFetching: false,
          loading: 'succeeded',
          message_text: payload.message,
      }),
    [FETCH_CONTRACTS_REQUEST]: (state, payload) =>
        Object.assign({}, state, {
            isFetching: true,
            loading: 'pending',
            message_text: payload.message,
            filtered: payload.filtered
        }),
    [FILTER_CONTRACTS_REQUEST]: (state, payload) =>
        Object.assign({}, state, {
            filterContract: payload.contract,
            filtered: payload.filtered
        }),
    [CLEAR_CONTRACTS_FILTER]: (state) =>
        Object.assign({}, state, {
            filterContract: null,
            filtered: false,
        }),
    [EXPORT_CONTRACT_REQUEST]: state =>
        Object.assign({}, state, {
            downloading_contract: true,
        }),
    [EXPORT_CONTRACT_DONE]: state =>
        Object.assign({}, state, {
            downloading_contract: false,
        }),
    [MESSAGE_CONTRACT]: (state, payload) => {
      return Object.assign({}, state, {
            message: payload.message,
        })
    },
    EDIT_CONTRACT: (state, payload) => {
      return Object.assign({}, state, {
        editFormOpen: payload
      });
    },
    VIEW_CONTRACT: (state, payload) =>
        Object.assign({}, state, {
          view_contract: payload
        }),
    FETCH_ADDRESSES: (state, payload) =>
      Object.assign({}, state, {
        availableAddresses: payload
      }),
    SET_ADDRESS_REQUEST: (state, payload) =>
      Object.assign({}, state, {
        sending: true
      }),
    SET_ADDRESS: (state, payload) => {
      const { view_contract } = state;
      const c = Object.assign({}, view_contract, {
        notification_address: payload
      });
      const items = state.items.map((contract) => {
        if (contract.name === view_contract.name) {
          return Object.assign({}, contract, {
            notification_address: payload
          })
        } else {
          return contract
        }
      });
      return Object.assign({}, state, {
        view_contract: c,
        items
      });
    },
    SET_IBAN: (state, payload) => {
      const { view_contract } = state;
      const c = Object.assign({}, view_contract, {
        bank_account: payload
      });
      const items = state.items.map((contract) => {
        if (contract.name === view_contract.name) {
          return Object.assign({}, contract, {
            bank_account: payload
          })
        } else {
          return contract
        }
      });
      return Object.assign({}, state, {
        view_contract: c,
        items
      });
    },
    SET_ADDRESS_DONE: (state, payload) =>
      Object.assign({}, state, {
        sending: false,
      }),
    SET_ADDRESS_ERROR : (state, payload) =>
      Object.assign({}, state, {
        sending: false,
    }),
    SET_PHONE: (state, payload) => {
      const { view_contract } = state;
      const { notification_address } = view_contract;
      const c = Object.assign({}, notification_address, {
        mobile: payload
      });
      const items = state.items.map((contract) => {
        if (contract.name === view_contract.name) {
          return Object.assign({}, contract, {
            notification_address: c
          })
        } else {
          return contract
        }
      });
      return Object.assign({}, state, {
        view_contract: Object.assign({}, view_contract, {notification_address: c}),
        items
      });
    },
    SET_EMAIL: (state, payload) => {
        const { view_contract } = state;
        const { notification_address } = view_contract;
        const c = Object.assign({}, notification_address, {
          email: payload
        });
        const items = state.items.map((contract) => {
          if (contract.name === view_contract.name) {
            return Object.assign({}, contract, {
              notification_address: c
            })
          } else {
            return contract
          }
        });
        return Object.assign({}, state, {
          view_contract: Object.assign({}, view_contract, {notification_address: c}),
          items
        });
      },
      SET_CESSION_SIPS: (state, payload) => {
        const { view_contract } = state;
        const items = state.items.map((contract) => {
          if (contract.name === view_contract.name) {
            return Object.assign({}, contract, {
              no_cession_sips: payload
            })
          } else {
            return contract
          }
        });
        return Object.assign({}, state, {
          view_contract: Object.assign({}, view_contract, {
            no_cession_sips: payload
          }),
          items
        });
      },
    SET_ELECTRONIC_COMUNICATION: (state, payload) => {
        const { view_contract } = state;
        const items = state.items.map((contract) => {
          if (contract.name === view_contract.name) {
            return {
              ...contract,
              ...payload,
            }
          } else {
            return contract
          }
        });
        return Object.assign({}, state, {
          view_contract: {
              ...view_contract,
              ...payload,
          },
          items
        });
      },
      SET_ATR_AVAILABLE: (state, payload) => {
        const { view_contract } = state;
        const items = state.items.map((contract) => {
          if (contract.name === view_contract.name) {
            return Object.assign({}, contract, {
              atr_available: payload
            })
          } else {
            return contract
          }
        });
        return Object.assign({}, state, {
          view_contract: Object.assign({}, view_contract, {
            atr_available: payload
          }),
          items
        });
      },
      SET_INVOICE_ONLINE: (state, payload) => {
        const { view_contract } = state;
        const items = state.items.map((contract) => {
          if (contract.name === view_contract.name) {
            return Object.assign({}, contract, {
              invoice_online: payload
            })
          } else {
            return contract
          }
        });
        return Object.assign({}, state, {
          view_contract: Object.assign({}, view_contract, {
            invoice_online: payload
          }),
          items
        });
      },
      [LOGOUT_SUCCESS]: (state, payload) =>
        Object.assign({}, state, initialState),
});
