import { forwardRef, useEffect, useState } from "react";
import { default as couponService } from "@/services/coupons";
import queryString from "query-string";

import { 
  TextField, FormHelperText, Grid
} from "@mui/material";
import { Button } from "@gisce/oficina-virtual-components";
import { useTranslation } from "react-i18next";
import { Noop, RefCallBack } from "react-hook-form";

interface ICouponFieldProps {
  onChange: (...event: any[]) => void;
  onBlur: Noop;
  value: string;
  ref: RefCallBack;
  outterIsSubmitting: boolean;
}

type Ref = HTMLDivElement;

const CouponField = forwardRef<Ref, ICouponFieldProps>(({
  onChange,
  onBlur,
  value = [],
  outterIsSubmitting = false
}, ref) => {
  const { t } = useTranslation();

  const [innerValue, setInnerValue] = useState(value);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [isSubmitting, setIsSubmitting] =  useState(outterIsSubmitting);
  const [couponHasError, setCouponHasError] = useState(false);

  useEffect(() => {
    const { promocode: promocodeQS } = queryString.parse(
      location.search, {arrayFormat: 'comma'}
    ) as { promocode: string };

    if (promocodeQS) {
      setTextFieldValue(promocodeQS);
      validateCoupons(promocodeQS);
    }
    
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Inner component
    setTextFieldValue(event.target.value);
    setCouponHasError(false);
  }

  const validateCoupons = (promocode: string) => {

    setIsSubmitting(true);
    couponService.validateCoupon(promocode)
    .then( res => {
      if(res.valid) {
        setInnerValue(promocode);
        onChange(promocode);
        setTextFieldValue('');
      } else {
        setTextFieldValue(promocode);
        setCouponHasError(true);
      }
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  }

  const applyChange = () => {
    if(textFieldValue) {

      // Si el cupó ja ha estat activat
      if (innerValue === textFieldValue) {
        return;
      }

      validateCoupons(textFieldValue);
    }
  }

  return (
    <>
      <Grid container spacing={1} 
        sx={{width: {md: "50%", xs: "100%"}, mb: "10px"}}
      >
        <Grid item xs={10}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={textFieldValue}
            onChange={handleChange}
            onBlur={onBlur}
            ref={ref}
            disabled={isSubmitting}
            error={!isSubmitting && couponHasError}
          />

          {!isSubmitting && couponHasError && 
            <FormHelperText error={true}>
              {t("common:text.contractation_promotional_invalid")}
            </FormHelperText>
          }

        </Grid>

        <Grid item xs={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            loading={isSubmitting}
            onClick={() => applyChange()}
          >
            {t("common:text.contractation_promotional_apply")}
        </Button>
        </Grid>
      </Grid>
    </>
  )
});

export default CouponField;