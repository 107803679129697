import React from "react";

import {FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText} from "@mui/material"

const RadioGroupField = ({
  input: { onChange, value },
  radioElements,
  onUpdate = null,
  meta,
  label,
  ...rest
}) =>
  <>
    <FormControl style={{width: "100%"}}>
      {label}
      <RadioGroup
        name="anything"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
          if (typeof onUpdate === "function") {
            onUpdate(event.target.value);
          }
        }}
        style={{width: "100%"}}
        {...rest}
      >
        {
          radioElements.map(({label, val, style}, idx) =>
            <FormControlLabel key={idx} value={val} style={style} control={<Radio />} label={label}/>
          )
        }
      </RadioGroup>
    </FormControl>
    {meta.error && meta.touched && (
      <FormHelperText error={true} id="radio-group-error-text">
        {meta.error}
      </FormHelperText>
    )}
  </>

export default RadioGroupField;
