import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MoveVirtualBatteryStep1 from "./MoveVirtualBatteryStep1";
import MoveVirtualBatteryStep2 from "./MoveVirtualBatteryStep2";
import useMoveVirtualBatteryContext from "../useMoveVirtualBatteryContext";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import MoveVirtualBatteryStep3 from "./MoveVirtualBatteryStep3";
import { useTranslation } from "react-i18next";

function getStepContent(step, nextStepCallback) {
  switch (step) {
    case 0:
      return <MoveVirtualBatteryStep1 nextStepCallback={nextStepCallback} />;
    case 1:
      return <MoveVirtualBatteryStep2 nextStepCallback={nextStepCallback} />;
    case 2:
      return <MoveVirtualBatteryStep3 nextStepCallback={nextStepCallback} />;
    case 3:
      return (
        <CircularProgress
          color="secondary"
          style={{ marginRight: 10 }}
          size={25}
        />
      );
    default:
      return "Unknown step";
  }
}

export const HorizontalLinearStepper = ({ onClose, onSubmit, disabled }) => {
  const { t } = useTranslation();
  const { selectedBatteryId } = useMoveVirtualBatteryContext();
  const { waitingChangeRequest } = useSelector((state) => state.bateriaVirtual);

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    t("common:text.select_battery"),
    t("common:text.virtual_battery_modify_percentage"),
    t("common:text.virtual_battery_move_step_3_title_finalitza"),
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = () => {
    setActiveStep(3);
    onSubmit();
  };

  return (
    <Box sx={{width: "100%"}}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
          <div>
            <Typography sx={{marginTop: 1, marginBottom: 1}}>
              {getStepContent(activeStep, handleNext)}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Button
                  variant="text"
                  color="primary"
                  onClick={onClose}
                  disabled={activeStep === 3}
                >
                  {t("common:text.payment_form_cancel")}
                </Button>
              </div>
              <div>
                <Button
                  disabled={activeStep === 0 || activeStep === 3}
                  onClick={handleBack}
                  sx={{marginRight: 1}}
                >
                  {t("common:text.virtual_battery_move_stepper_anterior")}
                </Button>

                {activeStep < 2 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    sx={{marginRight: 1}}
                    disabled={!selectedBatteryId}
                  >
                    {t("common:text.virtual_battery_move_stepper_seguent")}
                  </Button>
                )}
                {activeStep === 2 && (
                  <Button
                    key={"send"}
                    color={"primary"}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={disabled}
                  >
                    {waitingChangeRequest ? (
                      <CircularProgress
                        color="secondary"
                        style={{ marginRight: 10 }}
                        size={25}
                      />
                    ) : null}
                    {t("common:text.virtual_battery_move_stepper_confirm")}
                  </Button>
                )}
              </div>
            </Box>
          </div>
      </div>
    </Box>
  );
};

export default HorizontalLinearStepper;
