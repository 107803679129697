import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as electricityContractationActions from "../../actions/electricityContractation";
import * as gasContractationActions from "../../actions/gasContractation";
import FormAboutInstallation from "./FormAboutInstallation";
import FormAboutYou from "./FormAboutYou";
import FormAboutContract from "./FormAboutContract";
import FormAboutGasContract from "./FormAboutGasContract";
import FormPayment from "./FormPayment";
import Confirm from "./Confirm";
import Confirmed from "./Confirmed";
import Settings from "../../settings";
import { collectFormData } from "./form-data";

const useSteps = ({hasGas}) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const elecContractation = useSelector((state) => state.electricityContractation);
  const gasContraction = useSelector((state) => state.gasContractation);

  const stepLimit = 6;
  const [completedSteps, setCompletedSteps] = useState(elecContractation.completedSteps);

  const dispatch = useDispatch();

  const collectData = (values, step) => {
    const collectedData = collectFormData(values);
    collectedData.step = step;

    let gasCollectedData = {};
    if (hasGas && gasNewContract.cups) {
      gasCollectedData = collectFormData({
        ...elecContractation.electricity,
        cups: gasContraction.cups,
        comerOrigin: gasContraction.comerOrigin,
        accessTariff: gasContraction.accessTariff,
        selectedProduct: gasContraction.selectedProduct,
        invoice: gasContraction.invoice,
        owner: {
          ...elecContractation.electricity.owner,
          changeOwner: gasContraction.changeOwner,
        },
      });
      gasCollectedData.step = step;
    }
    return { collectedData, gasCollectedData };
  }

  const dispatchFormData = (leadName, values) => {
    switch (leadName) {
      case "installation":
        dispatch(electricityContractationActions.setAboutInstallationData(values));
        break;
      case "person":
        dispatch(electricityContractationActions.setAboutYouData(values));
        break;
      case "energy":
        dispatch(electricityContractationActions.setAboutContractData(values));
        break;
      case "payment":
        dispatch(electricityContractationActions.setPaymentData(values));
        break;
      case "gas":
        dispatch(gasContractationActions.submitAboutContractData(values)); 
        break;
      default:
    }
  }

  const shouldSaveLeads = () => {
    let r = Settings?.features?.leads &&
      (elecContractation.leadsConsent || isAuthenticated) &&
      elecContractation.currentStep.index !== stepLimit;
    return r
  }

  const handleCompleteStep = () => {
    const newCompletedSteps = {...completedSteps};
    newCompletedSteps[elecContractation.currentStep.index] = true;
    setCompletedSteps(newCompletedSteps);
  }

  const handleSubmit = async (values, leadName) => {
    const { collectedData, gasCollectedData } = collectData(values, elecContractation.currentStep.index + 1);

    dispatch(electricityContractationActions.resetLeadsErrors());
    dispatchFormData(leadName, values);

    handleCompleteStep();

    if (leadName === "confirmation") {
      collectedData.confirm = true;
      gasCollectedData.confirm = true;
      dispatch(electricityContractationActions.setConfirmData(values));
      await dispatch(electricityContractationActions.confirmNewContract(
        { FormConfirm: collectedData },
        { 
          "currentStep": elecContractation.currentStep.index,
          "nextStep": {
            index: elecContractation.currentStep.index + 1,
            name: steps[elecContractation.currentStep.index + 1].name
          }
        }
      ));
      if (hasGas) {
        dispatch(gasContractationActions.confirmNewContract(gasCollectedData));
      }

    } else if (shouldSaveLeads()) {
      await dispatch(
        electricityContractationActions.sendElectricityLeads(
          collectedData, 
          { 
            ...(hasGas && {"gasData": gasCollectedData}),
            "currentStep": elecContractation.currentStep.index,
            "nextStep": {
              index: elecContractation.currentStep.index + 1,
              name: steps[elecContractation.currentStep.index + 1].name
            },
            "checkSolvencia": (leadName === "person") ? Settings.contractation?.shouldCheckMorosity : false,
            "checkDNI": (leadName === "person") ? Settings.contractation?.shouldCheckDNI : false,
          }
        )
      );

    }
  }

  const handleGoBack = () => {
    const prevStepIdx = elecContractation.currentStep.index - 1;
    if (elecContractation.currentStep.name !== 'installation') {
      dispatch(electricityContractationActions.resetLeadsErrors());
      dispatch(electricityContractationActions.setLeadCurrentStep(
        {index: prevStepIdx, name: steps[prevStepIdx].name}
      ));
    }
  };

  let steps = [
    {
      name: 'installation',
      label: 'common:text.contractation_about_installation',
      content: <FormAboutInstallation
          onSubmit={(values) => handleSubmit(values, "installation")}
          gas={hasGas}
          selectComerOrigin={Settings?.contractation?.selectComerOrigin}
        />
    },
    {
      name: 'person',
      label: 'common:text.contractation_about_you',
      content: <FormAboutYou
          onSubmit={(values) => handleSubmit(values, "person")}
          gas={hasGas}
          selectComerOrigin={Settings?.contractation?.selectComerOrigin}
          onGoBack={handleGoBack}
        />
    },
    {
      name: 'energy',
      label: 'common:text.contractation_about_contract',
      content: <FormAboutContract
          onSubmit={(values) => handleSubmit(values, "energy")}
          gas={hasGas}
          selectComerOrigin={Settings?.contractation?.selectComerOrigin}
          onGoBack={handleGoBack}
        />
    },
    {
      name: 'gas',
      label: 'common:text.contractation_about_gas_contract',
      content: <FormAboutGasContract
          onSubmit={(values) => handleSubmit(values, "gas")}
          gas={hasGas}
          selectComerOrigin={Settings?.contractation?.selectComerOrigin}
          onGoBack={handleGoBack}
        />
    },
    {
      name: 'payment',
      label: 'common:text.contractation_about_payment',
      content: <FormPayment
          onSubmit={(values) => handleSubmit(values, "payment")}
          gas={hasGas}
          selectComerOrigin={Settings?.contractation?.selectComerOrigin}
          onGoBack={handleGoBack}
        />
    },
    {
      name: 'confirmation',
      label: 'common:text.contractation_confirmation',
      content: <Confirm
          onSubmit={(values) => handleSubmit(values, "confirmation")}
          gas={hasGas}
          selectComerOrigin={Settings?.contractation?.selectComerOrigin}
          onGoBack={handleGoBack}
        />
    },
    {
      name: 'finish',
      label: 'common:text.contractation_confirmed',
      content: <Confirmed
          onSubmit={(values) => handleSubmit(values, "finish")}
          gas={hasGas}
          selectComerOrigin={Settings?.contractation?.selectComerOrigin}
          onGoBack={handleGoBack}
        />
    }
  ];

  const removeStep = (stepName) => {
    steps = steps.filter(step => step.name !== stepName);
  }

  if (!hasGas) {
    removeStep('gas');
  }

  if (Settings?.contractation?.businessHaltContractation && elecContractation.company) {
    removeStep('energy');
    removeStep('gas');
    removeStep('payment');
  }

  return {
    steps,
    completedSteps
  }

}

export default useSteps;