import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterContractsInvoicesRequest, viewContract, fetchContracts } from '../../actions/contracts';
import _ from "lodash";
import { locale_code } from '../../constants/i18n';
import { regional_settings } from '../../constants';
import Settings from "../../settings";

import { ContentHeader } from '../ContentHeader';

import { Notification } from '../Notification';
import { LoadingAnimation } from '../LoadingAnimation';
import { SmartTable } from '@gisce/oficina-virtual-components'

import { Box, Button } from '@mui/material';
import { ArrowForward, ArrowBack, Receipt, ZoomIn, ErrorOutline } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const style = {
    buttonAdd: {
        marginRight: 20,
    },
    buttonPosition: {
        textAlign: 'right',
    },
    table: {
        marginTop: 20,
    },
    iconBefore: {
        marginRight: '10px',
    },
};

const ContractsListElectricity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { 
    items: contracts = [], 
    loading, 
    isFetching, 
    filtered, 
    total_items: totalItems = 0 
  } = useSelector((state) => state.contracts);
  const { t } = useTranslation();

  const [messageText, setMessageText] = useState(contracts?.message_text);
  const [messageOpen, setMessageOpen] = useState(null);
  const [offset, setOffset] = useState(contracts?.offset || 0);
  const [pageItems, setPageItems] = useState(contracts?.page_items || Settings?.contracts?.itemsPerPage || 100);
  const [searchText, setSearchText] = useState(null);
  
  useEffect(() => {
    if (loading === 'idle' || filtered) {
      fetchData();
    }
  }, []);

  const fetchData = (initial=true, offset=0, filter=null) => {
    dispatch(fetchContracts(token, initial, offset, filter));
  };

  const refreshData = () => {
    fetchData(false);
    setMessageOpen(true);
  };

  const addContracts = () => {
    navigate(t('common:url.electricityContractation')); // TODO Add logic here
  }

  const goToInvoice = (contract) => {
    dispatch(filterContractsInvoicesRequest(contract, contract.name));
    navigate(t('common:url.contracts_invoices', {contractName: contract?.name}));
  };

  const goToContract = (id) => {
    const contract = contracts.filter((c) => c.name === id)[0];
    dispatch(viewContract(contract));
    navigate(t('common:url.contracts_detail', {contractName: contract.name}));
  };

  const previousPage = () => {
    const newOffset = offset - pageItems;
    setOffset(newOffset);
    fetchData(false, newOffset);
  };

  const nextPage = () => {
    const newOffset = offset + pageItems;
    setOffset(newOffset);
    fetchData(false, newOffset);
  };

  const onSearch = (searchText) => {
    setSearchText(searchText);
    fetchData(false, 0, searchText);
  };

  const JSON_to_arrays = () => {
    let columns = [
      {
        title: t('common:text.contracts_contract'),
        key: "name",
      },
      {
        title: t('common:text.contracts_cups'),
        key: "cups",
      },
      {
        title: t('common:text.contracts_direction'),
        key: "address",
      },
      {
        title: t('common:text.contracts_power'),
        key: "power",
      },
      {
        title: t('common:text.contracts_product'),
        key: "tariff",
        removeSelf: !Settings?.contracts?.showProduct
      },
      {
        title: t('common:text.contracts_power'),
        key: "power",
        removeSelf: !Settings?.contracts?.showPower
      },
      {
        title: t('common:text.contracts_state'),
        key: "state",
      },
      {
        title: t('common:text.contracts_payment'),
        key: "payment",
      },
      {
        title: t('common:text.contracts_actions'),
        key: "actions",
      },
    ];

    // Remove any item with removeSelf set to true
    columns = columns.filter(item => !item.removeSelf);

    const contractsSource = contracts ? contracts.map( (contract, index) => {

      const invoices_button = (
        <Button
          key={"invoices"}
          variant={'contained'}
          color={'primary'}
          disabled={!contract.name}
          title={t("common:text.contract_related_invoices")}
          onClick={() => goToInvoice(contract)}
          style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden' }}
        >
          {<Receipt style={style.iconBefore} />}
          {t('common:text.contracts_view_invoices_button_button')}
        </Button>
      );

      const contract_button = (
        <Button
          key={"contract"}
          variant={'contained'}
          color={'primary'}
          disabled={!contract.name}
          title={t("common:text.contract_view_detail")}
          onClick={() => goToContract(contract.name)}
          style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden' }}
        >
          {<ZoomIn style={style.iconBefore} />}
          {t('common:text.contracts_view_contracts_button_button')}
        </Button>
      );

      let contractStatus;
      switch (contract.state) {
        case 'esborrany':
          contractStatus = t('common:text.contract_status_draft');
          if (!contract.sign_date) {
            contractStatus = t('common:text.contract_status_signature_pending');
          }
          break;
        case 'activa':
          contractStatus = t('common:text.contract_status_open');
          break;
        case 'baixa':
          contractStatus = t('common:text.contract_status_closed');
          break;
        default:
          contractStatus = t('common:text.contract_status_closed');
          break;
      }

      const hasDebt = contract.amount_debt > 0;
      const contractPayment = hasDebt ? `${t('common:text.contract_debt')}  (${contract.amount_debt.toLocaleString(locale_code)} €)` : '';
      const contractedPowers = (contract.contracted_power_period && contract.contracted_power_period.length) ? contract.contracted_power_period.reduce((accumulator, currentValue) => {
        return `${accumulator} ${currentValue.period}: ${currentValue.power.toLocaleString(locale_code)} ${regional_settings.potence_unit}`;
      }, '') : '';

      const values = {
        id: index,
        name: contract.name,
        cups: contract.cups.name,
        address: `${contract.cups.street} - ${contract.cups.city}`,
        tariff: (contract.pricelist && contract.pricelist.nom_comercial) ? contract.pricelist.nom_comercial : "",
        power: contractedPowers,
        state: contractStatus,
        payment: contractPayment,
        actions: [invoices_button, contract_button]
      };

      return values;
    }) : [];

    return {
      columns,
      contractsSource
    };
  }

  const getStatusForItem = (item) => {
    if (item?.hasDebt) {
      return <ErrorOutline style={{color: '#FF0000'}}/>
    }
  }

  const renderContractList = () => {

    const adaptedData = (loading === 'succeeded') && JSON_to_arrays();

    const start_items = contracts.offset_items;
    const end_items = Math.min(start_items + contracts.page_items, contracts.total_items);

    return (
      <>
        <Notification
          message={messageText}
          open={messageOpen}
        />

        <ContentHeader
          title={t('common:text.contracts_view_title')}
          addButton={Settings.contractation?.electricity?.enabled}
          addClickMethod={() => addContracts()}

          refreshButton={true}
          refreshClickMethod={() => refreshData()}
          onSearch={onSearch}
        />
        {loading === 'succeeded' ?
          <>
            <Box mt={3}>
              <SmartTable
                columns={ adaptedData?.columns ?? []} 
                dataSource={ adaptedData?.contractsSource ??[] }
                loading={loading === 'pending'}
                onRowStatus={getStatusForItem}
                loadingComponent={<LoadingAnimation />}
              />
            </Box>
            {(contracts.page_items < contracts.total_items) &&
              <div style={{ textAlign: "center" }}>
                {(start_items > 0) &&
                  <Button
                    variant={'text'}
                    disabled={contracts.isFetching}
                    onClick={() => previousPage()}
                  >
                    {<ArrowBack />}
                    {"Anterior"}
                  </Button>
                }
                <span>Mostrando de {start_items} a {end_items} de {contracts.total_items}</span>
                {(end_items <= contracts.total_items) &&
                  <Button
                    variant={'text'}
                    disabled={contracts.isFetching}
                    onClick={() => nextPage()}
                  >
                    {"Siguiente"}
                    {<ArrowForward />}
                  </Button>
                }
              </div>
            }
            </>
          :
          <LoadingAnimation/>
        }
        
      </>
    );
  };

  return renderContractList();
}

export default ContractsListElectricity;
