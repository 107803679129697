import { SignUpData } from "@/types/definitions/signup";
import {
  PERSIST_SIGNUP_DATA
} from "../constants";

// ----------------------------------------------------------------------------
// Sign Up
// ----------------------------------------------------------------------------

interface IPersistData extends SignUpData {};

export const persistData = (formData: IPersistData) => {
	return {
		type: PERSIST_SIGNUP_DATA,
		payload: formData
	};
};