import React from "react";

import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material"

const CheckboxField = ({
  input: { onChange, value },
  onUpdate = null,
  meta,
  label,
  ...rest
}) => (
  <>
    <FormControlLabel
      control={
        <Checkbox
          checked={!!value}
          onChange={(event, isInputChecked) => {
            onChange(isInputChecked);
            if (typeof onUpdate === "function") {
              onUpdate(isInputChecked);
            }
          }}
          {...rest}
        />
      }
      label={label}
    />
    { meta.error && meta.touched &&
      <FormHelperText error={true} id="vivenda-habitual-error-text">
        {meta.error}
      </FormHelperText>
    }
  </>
);

export default CheckboxField;
