import { createReducer } from '../utils/misc';

import {
  PERSIST_SIGNUP_DATA,
} from '../constants/index';

const initialState = {
  nif: '',
  contract: '',
  email: '',
  email2: '',
  invoiceNumber: '',
  acceptConditionsAndGdpr: false,
  phone: '+34'
};

export default createReducer(initialState, {
  [PERSIST_SIGNUP_DATA]: (state, payload) => {
    return payload
  }
});
