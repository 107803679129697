import { Box, Paper, Typography } from "@mui/material";
import useBatteryDistributionPreview from "./useBatteryDistributionPreview";
import {
  BatteryDistributionAfter,
} from "./BatteryDistributionCard";
import { i18n } from "../../../config";
import { useSelector } from "react-redux";
import useMoveVirtualBatteryContext from "../MoveVirtualBattery/useMoveVirtualBatteryContext";

export const MoveBatteryDistributionPreview = ({
  newPercentage,
  selectedBatteryId,
}) => {
  const { bateriaVirtualToMove, selectedBatteryDistribution } =
    useMoveVirtualBatteryContext();

  const { items, view_contract } = useSelector((state) => state.contracts);
  const { getExpectedDistribution } = useBatteryDistributionPreview();

  const oldExpectedDistribution = getExpectedDistribution({
    contracts: items,
    targetBatteryId: bateriaVirtualToMove.bateria.id,
    removedContractId:view_contract.id,
    expectedAction: "removed",
  });
  const newExpectedDistribution = getExpectedDistribution({
    contracts: items,
    movedBatteryId: selectedBatteryId,
    newPercentage,
    expectedAction: "move",
    forceCurrentName: view_contract.name
  });
  console.log("fill de puta", items, newExpectedDistribution, selectedBatteryId)

  return (
    <>
      <Box>
        <Typography variant="h6">
          {i18n.t("common:text.virtual_batteries_status_after_confirmation")}
        </Typography>
        <Paper
          style={{
            padding: "1rem",
            overflowY: "auto",
            maxHeight: "35vh",
          }}
          variant="outlined"
        >
          <Box style={{ position: "relative" }}>
            <Typography variant="subtitle2">

              {i18n.t("common:text.altHome_virtual_battery")} <u>{i18n.t("common:text.battery_destination")}</u>:
              { selectedBatteryDistribution["batteryName"] }
            </Typography>
            <BatteryDistributionAfter
              batteryDistribution={newExpectedDistribution}

            />
            <hr />
            <Typography variant="subtitle2">
              {i18n.t("common:text.altHome_virtual_battery")} <u>{i18n.t("common:text.battery_distribution_selected_contract_name")}</u>:
              {bateriaVirtualToMove["bateria"]["name"]}
            </Typography>
            <BatteryDistributionAfter
              batteryDistribution={oldExpectedDistribution}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default MoveBatteryDistributionPreview;
