import useVirtualBattery from "../useVirtualBattery";

export const useBatteryDistributionPreview = () => {
  const bateriaVirtualTool = useVirtualBattery();

  const getExpectedDistributionWhenChangedPercentage = ({contracts, editedBatteryId, contractUpdated, newPercentage, forceCurrentName}) => {
    let newExpectedDistribution = [];
    if (editedBatteryId) {
      const newBatteryDistribution = bateriaVirtualTool.getBatteryDistribution({
          contracts,
          batteryId: editedBatteryId
      });

      // INFO: Remove from the distribution the contract being updated to avoid conflict with the new percentage
      newBatteryDistribution.distribution = newBatteryDistribution.distribution.filter((x) => {
        return x.contractId !== contractUpdated
      });

      // INFO: Compute the expected distribution including the newPercentage
      newExpectedDistribution = computeExpectedDistribution({
        currentBatteryDistribution: newBatteryDistribution,
        newPercentage,
        includeCurrent: true
      });

      const contractVirtualBattery = newExpectedDistribution["distribution"].find((x) => x.contractName === "current");
      if (contractVirtualBattery)
        contractVirtualBattery.contractName = forceCurrentName
    }
    return newExpectedDistribution;
  };

  const getExpectedDistributionWhenMovedBattery = ({contracts, movedBatteryId, newPercentage, forceCurrentName}) => {
    let newExpectedDistribution = [];
    if (movedBatteryId) {

      // INFO: Get the info for the future "new" battery
      const newBatteryDistribution = bateriaVirtualTool.getBatteryDistribution({
          contracts,
          batteryId: movedBatteryId
      });

      newExpectedDistribution = computeExpectedDistribution({
        currentBatteryDistribution: newBatteryDistribution,
        newPercentage,
        includeCurrent: true
      });

      const contractVirtualBattery = newExpectedDistribution["distribution"].find((x) => x.contractName === "current");
      if (contractVirtualBattery)
        contractVirtualBattery.contractName = forceCurrentName
    }
    // INFO: Return a new object combining the original battery distribution with the updated expected distribution.
    return newExpectedDistribution;
  };


  const getRemovedExepectedDistribution = ({contracts, targetBatteryId, removedContractId}) => {
    let newExpectedDistribution = [];
    if (targetBatteryId && removedContractId) {
      // INFO: Get the info for the future "new" battery
      const newBatteryDistribution = bateriaVirtualTool.getBatteryDistribution({
          contracts,
          batteryId: targetBatteryId,
      });

      // INFO: Remove from the distribution the removed contract
      newBatteryDistribution.distribution = newBatteryDistribution.distribution.filter((x) => {
        return x.contractId !== removedContractId
      });

      newExpectedDistribution = computeExpectedDistribution({
        currentBatteryDistribution: newBatteryDistribution,
        newPercentage: 0,
        includeCurrent: false
      });
    }
    // INFO: Return a new object combining the original battery distribution with the updated expected distribution.
    return newExpectedDistribution;
  };


  const getExpectedDistribution = ({expectedAction,  ...rest}) => {
    let batteryDistribution;
    if (expectedAction === "move") {
      batteryDistribution = getExpectedDistributionWhenMovedBattery(rest);
    } else if (expectedAction === "changePercentage") {
      batteryDistribution = getExpectedDistributionWhenChangedPercentage(rest);
    } else if (expectedAction === "removed") {
      batteryDistribution =  getRemovedExepectedDistribution(rest);
    } else {
      throw new Error(`The expectedAction: ${expectedAction} provided is not implemented`);
    }

    if (batteryDistribution) {
      batteryDistribution["distribution"] = bateriaVirtualTool.sortDistribution({distribution: batteryDistribution["distribution"]});
    }
    return batteryDistribution;
  };



  const computeExpectedDistribution = ({currentBatteryDistribution, newPercentage, includeCurrent=true}) => {
    function distribute(contractName, pes, pesPercentage, current=false, locked=false,) {
      return { contractName, pes, pesPercentage: pesPercentage.toFixed(2), current, locked }
    }
    let newExpectedDistribution = [];
    // INFO: Compute the future 100% percentage of current battery weights compared to the new percentage.
    let otherBVNewPercentage = 100 - newPercentage;

    // INFO: Calculate the sum of weights from the current battery distribution.
    let otherBVWeightSum = currentBatteryDistribution["distribution"].reduce((acumulator, distribution) => distribution["pes"] + acumulator, 0);
    let newWeight = 1;

    // INFO: If the other BV has wheight we can distribute
    if (otherBVWeightSum !== 0) {
      // INFO: Compute the new weight based on the new percentage and the sum of existing weights.
      newWeight = (otherBVWeightSum * newPercentage) / otherBVNewPercentage;

      // INFO: Calculate the total weight including the new weight.
      let newWeightSum = otherBVWeightSum + newWeight;

      // INFO:Iterate through each distribution entry to compute the new expected distribution percentages.
      for (const distribution of currentBatteryDistribution["distribution"]) {
        //let pes = (distribution["pes"] === 0) ? 1 : distribution["pes"]; // If current distribution weight is 0 unlock it setting to 1
        let pes = distribution["pes"]; // If current distribution weight is 0 unlock it setting to 1
        newExpectedDistribution.push(distribute(distribution["contractName"], distribution["pes"], (pes / newWeightSum * 100)
        , false, distribution["pes"] === 0));
      }
    } else {
      // INFO: If the sum of the other BV weights is 0, assume the new weight is 1 to allow for weight distribution with the desired new percentage.
      const otherBVqty = currentBatteryDistribution["distribution"].length;
      otherBVWeightSum = otherBVqty * 1; // Each other BV is assigned a default weight of 1

      // INFO: Calculate the new weight using the formula: (newPercentage * quantity of other BV) / (remaining percentage)
      newWeight = (otherBVqty*newPercentage) / otherBVNewPercentage;

      let newWeightSum = otherBVWeightSum + newWeight;

      // INFO: Iterate through each distribution entry to calculate the new expected distribution percentages.
      for (const distribution of currentBatteryDistribution["distribution"]) {
        newExpectedDistribution.push(distribute(distribution["contractName"], distribution["pes"], (1 / newWeightSum * 100)
        , false, false));
      }
    }
    // INFO: Add an entry for the new weight with its percentage to the expected distribution.
    if (includeCurrent) newExpectedDistribution.push(distribute("current", newWeight, newPercentage, true));

    // INFO: Return a new object combining the original battery distribution with the updated expected distribution.
    return {
      ...currentBatteryDistribution,
      distribution: newExpectedDistribution,
    };
  };

  return {
    getExpectedDistribution
  };
};

export default useBatteryDistributionPreview;
